<template>
  <div class="main-container">
    <Header />

    <BlogDetailsWrapper />

    <Footer />

    <OffCanvasMobileMenu />
  </div>
</template>

<script>
import Header from "@/components/Header";
import BlogDetailsWrapper from "../components/sections/BlogDetailsWrapper";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

export default {
  components: {
    Header,
    BlogDetailsWrapper,
    Footer,
    OffCanvasMobileMenu,
  },
  data() {
    return {
      items: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Blog Details",
          active: true,
        },
      ],
    };
  },
  metaInfo: {
    title: "Putra Ramayana - Detil Promo",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
};
</script>
