<template>
  <div class="blog-inner">
    <div v-for="bannerpromo in bannerpromos" :key="bannerpromo.id_banner">
      <div class="media">
        <div class="image">
          <img :src="bannerpromo.url_gambar_banner" alt="image" />
        </div>
      </div>
      <div class="content">
        <ul class="meta">
          <li>
            {{ bannerpromo.tanggal_upload_banner }}
          </li>
        </ul>
        <h2 class="title">{{ bannerpromo.nama_banner }}</h2>
        <div class="desc section-space--bottom--30">
          <p>{{ bannerpromo.deskripsi_banner_01 }}</p>
          <p>{{ bannerpromo.deskripsi_banner_02 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["blogDetailsData"],
  name: "app",
  data() {
    return {
      bannerpromos: [],
    };
  },
  methods: {
    getData() {
      axios
        .get(
          "https://admin.putraramayana.com/api/bannerpromos/getbyid/" +
            this.$route.params.id_banner
        )
        .then((response) => {
          this.bannerpromos = response.data;
          console.log(bannerpromos);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
