<template>
  <div class="page-wrapper section-space--inner--120">
    <div class="blog-section">
      <div class="container">
        <div class="row blog-center">
          <div class="col-lg-9 order-1 order-lg-2">
            <div class="row">
              <div class="blog-details col-12">
                <BlogDetailsContent :blogDetailsData="data.blogDetails" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogSidebar from "@/components/sections/BlogSidebar";
import BlogDetailsContent from "@/components/BlogDetailsContent";
export default {
  components: {
    BlogSidebar,
    BlogDetailsContent,
  },
  data() {
    return {
      data,
    };
  },
};
</script>
